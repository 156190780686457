// Load Styles
import '../scss/main.scss';

// Load Bootstrap init
import { initBootstrap } from "./bootstrap.js";

// import Swiper bundle with all modules installed
import Swiper from 'swiper/bundle';

// import styles bundle
import 'swiper/css/bundle';

/* facts slider */
const swiperOne = new Swiper('#swipe-one', {
  speed: 500,
  spaceBetween: 60,
  freeMode: true,
  mousewheel: {
    releaseOnEdges: true,
  },
  simulateTouch: true,
  watchSlidesVisibility: true,
  breakpoints: {
    // when window width is >= 320px
    320: {
      slidesPerView: 1.3,
      spaceBetween: 20,
    },
    // when window width is >= 480px
    480: {
      slidesPerView: 2,
      spaceBetween: 20,
    },
    // when window width is >= 640px
    640: {
      slidesPerView: 3,
      spaceBetween: 20,
    },
    // when window width is >= 768px
    768: {
      slidesPerView: 4,
      spaceBetween: 30
    },
    // when window width is >= 992px
    992: {
      slidesPerView: 4,
      spaceBetween: 40
    },
    // when window width is >= 1200px
    1200: {
      slidesPerView: 5,
      spaceBetween: 40
    }
  }
});

/* client logos slider */
const swiperTwo = new Swiper('#swipe-two', {
  speed: 500,
  spaceBetween: 60,
  freeMode: true,
  mousewheel: {
    releaseOnEdges: true,
  },
  simulateTouch: true,
  watchSlidesVisibility: true,
  breakpoints: {
    // when window width is >= 320px
    320: {
      slidesPerView: 1.5,
    },
    // when window width is >= 480px
    480: {
      slidesPerView: 2,
      /* spaceBetween: 20, */
    },
    // when window width is >= 640px
    640: {
      slidesPerView: 2,
      /* spaceBetween: 20, */
    },
    // when window width is >= 768px
    768: {
      slidesPerView: 2.4,
      /* spaceBetween: 30 */
    },
    // when window width is >= 992px
    992: {
      slidesPerView: 3,
      /* spaceBetween: 0 */
    },
    // when window width is >= 1200px
    1200: {
      slidesPerView: 3.6,
      /* spaceBetween: 0 */
    },
    1400: {
      slidesPerView: 4,
      /* spaceBetween: 0 */
    }
  }
});



/* START for hero parallax on scroll */
/* import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger.js'; 
import { ScrollToPlugin } from 'gsap/ScrollToPlugin.js';  */

// Register the ScrollTrigger plugin
/* gsap.registerPlugin(ScrollTrigger,ScrollToPlugin); */

/* 
import $ from "./gsap.min.js";
import $ from "./ScrollTrigger.min.js";
import $ from "./ScrollToPlugin.min.js"; */
/* END for hero parallax on scroll */

// Loading bootstrap with optional features
initBootstrap({
  tooltip: false,
  popover: true,
  toasts: false,
});


// custome code


/* horizontal Accordion */
document.addEventListener('DOMContentLoaded', function () {
  var isFirstTime = true;

  window.addEventListener('scroll', function () {
    var whatSetsUsSection = document.getElementById('what-sets-us');

    if (whatSetsUsSection.classList.contains('inview') && isFirstTime) {
      const allAccordionCells = document.querySelectorAll('.accordion-cell');

      allAccordionCells.forEach((accordionCell, index) => {
        if (index === 0) {
          // Verzögerung von 2 Sekunden für das Expandieren des ersten Elements
          setTimeout(() => {
            accordionCell.classList.remove('collapsed');
            accordionCell.classList.add('expanded');
          }, 1000);
        } else {
          accordionCell.classList.remove('expanded');
          accordionCell.classList.add('collapsed');
        }
      });

      isFirstTime = false;
    }
  });

  // Funktion für Klick-Events
  function handleAccordionClick() {
    // Überprüfe, ob das angeklickte Element bereits erweitert ist
    const isExpanded = this.classList.contains('expanded');

    // Alle accordion-cell Elemente auf "collapsed" setzen
    const allAccordionCells = document.querySelectorAll('.accordion-cell');
    allAccordionCells.forEach((accordionCell) => {
      accordionCell.classList.remove('expanded');
      accordionCell.classList.add('collapsed');
    });

    // Wenn das angeklickte Element nicht erweitert war, setze es auf "expanded"
    if (!isExpanded) {
      this.classList.remove('collapsed');
      this.classList.add('expanded');
    }
  }


  // Eventlistener für Klick-Events zu den accordion-cell Elementen hinzufügen
  const accordionCells = document.querySelectorAll('.accordion-cell');
  accordionCells.forEach((accordionCell) => {
    accordionCell.addEventListener('click', handleAccordionClick);
  });

});


/* circle graphic hovers */
// Finde alle text-Elemente
var texts = document.querySelectorAll('text');

// Füge Event Listener für Mouseover und Mouseout hinzu
texts.forEach(function (text) {
  text.addEventListener('mouseover', function () {
    // Finde den übergeordneten 'text' oder 'textPath' für das aktuelle text
    var textParent = text.closest('text, textPath');

    // Überprüfe, ob ein übergeordnetes Element gefunden wurde
    if (textParent) {
      // Gehen Sie vier Ebenen höher, um das übergeordnete Element zu erhalten
      var higherParent = textParent.parentNode.parentNode.parentNode.parentNode;

      // Finde den zugehörigen circle und füge die Klasse 'active' hinzu
      var circle = higherParent.querySelector('circle');
      if (circle) {
        circle.classList.add('active');
      }
    }
  });

  text.addEventListener('mouseout', function () {
    // Entferne die Klasse 'active' von allen circles
    var circles = document.querySelectorAll('circle');
    circles.forEach(function (circle) {
      circle.classList.remove('active');
    });
  });
});


/* expand accordion to left if some accordion-item is shown/open  */
document.addEventListener('DOMContentLoaded', function () {
  // Hole alle Accordion-Button-Elemente
  const accordionButtons = document.querySelectorAll('#what-sets-us .accordion-cell');

  // Hole das überlappende Div
  const overlapDiv = document.querySelector('.overlap-to-left-md');

  // Überprüfe den Zustand der Accordion-Buttons
  function checkAccordionState() {
    const allButtonsClosed = Array.from(accordionButtons).every(button => !button.classList.contains('expanded'));

    // Füge oder entferne die Klasse "active" basierend auf dem Zustand der Accordion-Buttons
    if (allButtonsClosed) {
      overlapDiv.classList.remove('active');
    } else {
      overlapDiv.classList.add('active');
    }
  }

  // Event Listener für das Scroll-Ereignis hinzufügen
  window.addEventListener('scroll', checkAccordionState);


  // Füge einen Klick-Event-Listener zu jedem Accordion-Button hinzu
  accordionButtons.forEach(button => {
    button.addEventListener('click', function () {
      // Rufe die Funktion zur Überprüfung des Zustands auf, wenn ein Button geklickt wird
      checkAccordionState();
    });
  });

  // Rufe die Funktion zur Initialisierung auf
  checkAccordionState();
});


/* check if section is in viewport */
function addClassIfInView(entry) {
  if (entry.isIntersecting) {
    entry.target.classList.add('inview');
  } else {
    entry.target.classList.remove('inview');
  }
}

function createObserver(section) {
  var observer = new IntersectionObserver(function (entries) {
    entries.forEach(addClassIfInView);
  }, { threshold: 0.1 }); //setting how much of the object must be in the viewport

  observer.observe(section);
}

var sections = document.querySelectorAll('section');

sections.forEach(createObserver);


/* hide contact buttton if footer is "inview" */
document.addEventListener('DOMContentLoaded', function () {
  // Ziel-Elemente auswählen
  var contactElement = document.getElementById('contact');
  var completeOverviewElement = document.getElementById('complete-overview');
  var footerSectionElement = document.querySelector('.footer_section');
  var fixedContactButton = document.getElementById('fixed_contact_button');

  // Funktion zur Überprüfung der Klassen "inview"
  function checkInView() {
    var contactInView = contactElement.classList.contains('inview');
    var completeOverviewInView = completeOverviewElement.classList.contains('inview');
    var footerSectionInView = footerSectionElement.classList.contains('inview');

    // Überprüfe, ob mindestens eines der Elemente die Klasse "inview" hat
    if (contactInView || completeOverviewInView || footerSectionInView) {
      fixedContactButton.style.opacity = 0;
    } else {
      fixedContactButton.style.opacity = 1;
    }
  }

  // Observer für Mutationen im Ziel-Element (.footer_section)
  var observer = new MutationObserver(checkInView);

  // Konfiguration des Observers
  var config = { attributes: true };

  // Observer starten und initialen Check durchführen
  observer.observe(contactElement, config);
  observer.observe(completeOverviewElement, config);
  observer.observe(footerSectionElement, config);
  checkInView();
});

/* signet logo */
/* function toggleSignetVisibility() {
  var signet = document.getElementById('fixed_signet');
  if (window.scrollY >= 80) {
    signet.classList.add('active');
  } else {
    signet.classList.remove('active');
  }
}

// Event-Listener für das Scrollen hinzufügen
window.addEventListener('scroll', toggleSignetVisibility);
 */


/* header 2.0 */
import { preloadImages } from './utils.js';

// Variable to store the Lenis smooth scrolling object
let lenis;

// Selecting DOM elements

const contentElements = [...document.querySelectorAll('.content--sticky')];
const totalContentElements = contentElements.length;

// Initializes Lenis for smooth scrolling with specific properties
const initSmoothScrolling = () => {
  // Instantiate the Lenis object with specified properties
  lenis = new Lenis({
    lerp: 0.2, // Lower values create a smoother scroll effect
    smoothWheel: false // Enables smooth scrolling for mouse wheel events
  });

  // Update ScrollTrigger each time the user scrolls
  lenis.on('scroll', () => ScrollTrigger.update());

  // Define a function to run at each animation frame
  const scrollFn = (time) => {
    lenis.raf(time); // Run Lenis' requestAnimationFrame method
    requestAnimationFrame(scrollFn); // Recursively call scrollFn on each frame
  };
  // Start the animation frame loop
  requestAnimationFrame(scrollFn);
};

// Function to handle scroll-triggered animations
const scroll = () => {

  contentElements.forEach((el) => {

    gsap.timeline({
      scrollTrigger: {
        trigger: el,
        start: 'top top',
        end: '+=100%',
        scrub: true
      }
    })
      .to(el, {
        ease: 'none',
        startAt: { filter: 'brightness(100%)' },
        filter: 'brightness(50%)',
        scale: 0.95,
        borderRadius: 40
      }, 0);

  });

};

// Initialization function
const init = () => {
  initSmoothScrolling(); // Initialize Lenis for smooth scrolling
  scroll(); // Apply scroll-triggered animations
};

preloadImages('.content__img').then(() => {
  // Once images are preloaded, remove the 'loading' indicator/class from the body
  document.body.classList.remove('loading');
  init();
});

/* Fanning out the Header-Text  */
const stickyContent = gsap.utils.toArray(".content--sticky");

/* const firstStickyContent = document.querySelector(".content--sticky:first-of-type"); */


stickyContent.forEach((content) => {
  const observer = new MutationObserver((mutationsList, observer) => {
    for (const mutation of mutationsList) {
      if (mutation.type === 'attributes' && mutation.attributeName === 'style') {
        const computedStyle = window.getComputedStyle(content);
        const borderRadius = parseInt(computedStyle.getPropertyValue("border-radius")); // Annahme: Alle vier Ecken haben dieselbe Border-Radius-Größe

        if (borderRadius >= 1) {
          gsap.to(content, {
            scrollTrigger: {
              trigger: content,
              markers: false, // Remove this in production
              onEnter: () => {
                startAnimation(content);
              }
            }
          });

          // Sobald die Animation gestartet wurde und der Border-Radius überprüft wurde, beenden wir die Überwachung
          observer.disconnect();
        }
      }
    }
  });

  observer.observe(content, { attributes: true });
});

/* if (firstStickyContent) {
  startAnimation(firstStickyContent);
} */

function startAnimation(element) {
  let tl = gsap.timeline();

  tl.fromTo(element.querySelectorAll("svg.triple-fan-text .animate"), { yPercent: 0 }, { yPercent:400, duration: 0.4, stagger: 0.15 })
    .fromTo(element.querySelectorAll("svg.triple-fan-text .animate"), { autoAlpha: 1 }, { autoAlpha: 0, duration: 3, stagger: 0.2 });
}
